import exp from "constants";
import styled from "styled-components";

export const DetailedColumn = styled.div`
  display: flex;
  width: 75%;
  padding: 20px 40px;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    padding: 20px 15px;
    width: calc(100% - 30px);
  }
`