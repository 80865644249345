import styled from "styled-components";

export const Print = styled.div`
  cursor: pointer;
  height:100%;

  @media only screen and (max-width: 768px) {
    width: 80px;
  }

  @media only screen and (max-width: 500px) {
    width: 60px;
  }
`