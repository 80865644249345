import styled from "styled-components";

export const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  background: #FFF;
  height: 100%;
  box-shadow: #777 0 0 15px;
  position: relative;
  
  @media print {
    box-shadow: none;
  }
  
  @media only screen and (max-width: 768px){
    flex-direction: column;
  }
`