import styled from "styled-components";

export const Name = styled.div`
  display: flex;
  justify-self: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 2em;
  font-weight: 800;
  margin: 0;

  @media print {
    font-size: 1.5em;
  }
`