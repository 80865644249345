import styled from "styled-components";

export const PrintBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100px;
  height:40px;
  cursor: pointer;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  @media print {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    width: 200px;
    top: 10px;
    height:80px;
    right: 0;
  }

  @media only screen and (max-width: 500px) {
    width: 140px;
    top: 10px;
  }
`