import styled, { css } from "styled-components";

export const SummaryLine = styled.div<{ nowrap?: boolean }>`
  font-size: 1em;
  line-height: 1em;
  margin-top: 5px;
  padding: 2px 5px;
  ${props => props.nowrap && css`white-space: nowrap;`}
  
  @media print {
    font-size: 0.8em;
  }
`;