import { useCallback, useState } from 'react';
import './App.css';
import aws from './aws.png';
import { AboutMe } from "./Components/AboutMe";
import { CertImage } from "./Components/CertImage";
import { Container } from "./Components/Container";
import { DetailedColumn } from "./Components/DetailedColumn";
import { Keywords } from "./Components/Keywords";
import { LineBreak } from "./Components/LineBreak";
import { LinkNoBlue } from "./Components/LinkNoBlue";
import { Name } from "./Components/Name";
import { Photo } from "./Components/Photo";
import { PhotoContainer } from "./Components/PhotoContainer";
import { PositionTitle } from "./Components/PositionTitle";
import { Print } from "./Components/Print";
import { Split } from "./Components/Split";
import { SubTitle } from "./Components/SubTitle";
import { SummaryBlock } from "./Components/SummaryBlock";
import { SummaryColumn } from "./Components/SummaryColumn";
import { SummaryHeader } from "./Components/SummaryHeader";
import { SummaryIcon } from "./Components/SummaryIcon";
import { SummaryLine } from "./Components/SummaryLine";
import { WorkBlock } from "./Components/WorkBlock";
import { WorkBlockTitle } from "./Components/WorkBlockTitle";
import { WorkExperience } from "./Components/WorkExperience";
import email from './email.svg';
import flag from './ge_flag.svg';
import github from './github-mark.svg';
import linkedin from './linkedin.svg';
import photo from './photo.jpg';
import printer from './print.svg';
import incognito from './incognito.svg';
import upwork from './upwork.svg';
import { PrintBox } from './Components/PrintBox';
import { PrintIncognito } from './Components/PrintIncognito';
import { FullImage } from './Components/FullImage';
import { TinyImage } from './Components/TinyImage';
import { NoIncognito } from './Components/NoIncognito';

function App() {
  const printDoc = useCallback<React.MouseEventHandler<HTMLElement>>(({ currentTarget }) => {
    if (currentTarget.dataset.incognito !== undefined) {
      document.body.classList.add('incognito');
    }
    window.print()
    document.body.classList.remove('incognito');
  }, []);

  return <div className="App">
    <Container>
      <SummaryColumn>
        <Name>Leonid Babikov</Name>
        <PhotoContainer>
          <Photo src={photo} />
        </PhotoContainer>

        <Split />

        <SummaryBlock>
          <PrintBox>
            <PrintIncognito onClick={printDoc} data-incognito>
              <FullImage src={printer} />
              <TinyImage src={incognito} />
            </PrintIncognito>
            <Print onClick={printDoc} >
              <FullImage src={printer} />
            </Print>
          </PrintBox>
          <SummaryHeader>Location</SummaryHeader>
          <SummaryLine><SummaryIcon src={flag} /> Georgia, Batumi</SummaryLine>

          <SummaryHeader>Languages</SummaryHeader>
          <SummaryLine>English</SummaryLine>
          <SummaryLine>Russian</SummaryLine>

          <NoIncognito>
            <SummaryHeader>Contacts</SummaryHeader>
            <SummaryLine nowrap>
              <LinkNoBlue href='https://www.linkedin.com/in/leonid-babikov/'
                rel='noopener'
                target='_blank'>
                <SummaryIcon src={linkedin} alt='linkein logo' />
                LinkedIn</LinkNoBlue>
            </SummaryLine>
            <SummaryLine nowrap>
              <LinkNoBlue href='mailto:job@leonid.work'>
                <SummaryIcon src={email} alt='linkein logo' />
                job@leonid.work
              </LinkNoBlue>
            </SummaryLine>

            <SummaryHeader>Links</SummaryHeader>
            <SummaryLine nowrap>
              <LinkNoBlue href='https://www.upwork.com/freelancers/~0187760ab8fda9b142'
                rel='noopener'
                target='_blank'>
                <SummaryIcon src={upwork} alt='upwork logo' />
                UpWork</LinkNoBlue>
            </SummaryLine>
            <SummaryLine nowrap>
              <LinkNoBlue href='https://github.com/DynamicSTOP'
                rel='noopener'
                target='_blank'><SummaryIcon src={github} alt='github icon' />
                Github</LinkNoBlue>
            </SummaryLine>
          </NoIncognito>
        </SummaryBlock>


        <SummaryBlock>
          <SummaryHeader>Education</SummaryHeader>

          <SummaryLine>Russia, Southern Federal University - 2006 - 2011</SummaryLine>
          <SummaryLine>Specialist degree in Physics</SummaryLine>
        </SummaryBlock>

        <SummaryBlock>
          <SummaryHeader>Certs</SummaryHeader>
          <div>
            <LinkNoBlue href='https://www.credly.com/badges/7ccf97f6-e24c-4768-b966-74797b5dcf6d'><CertImage
              src={aws} /></LinkNoBlue>
          </div>
        </SummaryBlock>

      </SummaryColumn>

      <DetailedColumn>
        <PositionTitle>Web Developer</PositionTitle>
        <SubTitle>AWS \ NodeJS \ Typescript \ React</SubTitle>

        <AboutMe>I've been working as a WebDev for more than 10 years, mostly on e-learning projects and
          I am really passionate about seeing how my work helps other people to grow and learn new skills.</AboutMe>

        <LineBreak />

        <h2>Work experience</h2>

        <WorkBlock>
          <WorkBlockTitle>
            Upwork Freelancer Contract - Since Oct 2019
          </WorkBlockTitle>

          <WorkExperience>
            I am responsible for architecture and code of a serverless app hosted on AWS and supporting thousands of concurrent users.
            The goal of the project is to provide modular online events that would include several
            features like streaming, video\text chats, votes.
          </WorkExperience>

          <Keywords>Typescript - AWS Lambda - DynamoDB - S3 - API
            Gateway - CloudFormation - CloudFront - IVS - MediaConvert -
            GH Actions
          </Keywords>
        </WorkBlock>

        <LineBreak width='75%' margin='20px auto' />

        <WorkBlock>
          <WorkBlockTitle>
            Senior Software Developer - CarTrade - Sep 2018 - Oct 2019
          </WorkBlockTitle>
          <WorkExperience>
            I was doing major rework of the auto shop website.
            I was able to achieve following goals:
            <ul>
              <li>Packing whole environment into Docker image</li>
              <li>Added missing staging environment and adding CI for dev stage</li>
              <li>Adding caching layer</li>
              <li>Analytics and anti spam features</li>
              <li>Integrations with 3rd parties via XML lists</li>
              <li>Lazyload and automatic convert of used images</li>
            </ul>
            All of that resulted in a significant boost in SEO ranking and reducing workload on content editors and
            other
            team
            members.
          </WorkExperience>
          <Keywords>RubyOnRails PostrgreSQL Redis PHP JavaScript Docker
          </Keywords>
        </WorkBlock>

        <LineBreak width='75%' margin='20px auto' />

        <WorkBlock>
          <WorkBlockTitle>
            Senior Software Developer - Softgrad Solutions - Feb 2012 - Apr 2018
          </WorkBlockTitle>
          <WorkExperience>
            I was working in an international team on supporting and adding features to the e‐learning language
            platform.
            My key achievements are:
            <ul>
              <li>remaking DB structure related to Words, Translations and Phrase examples</li>
              <li>introducing Docker containers into several apps</li>
              <li>making Continuous Deployment for a Teacher‐Student messenger app</li>
              <li>extracting user related content into CDN</li>
              <li>integrations between apps (e.g. sharing assigned homeworks between main site and
                messenger)
              </li>
              <li>automate pdf generation using AWS Lambda and Chromium</li>
              <li>building up audio tracks from samples via ffmpeg</li>
            </ul>

          </WorkExperience>
          <Keywords>PHP JavaScript JQuery MySQL Docker Jenkins FFMPEG AWS S3 DynamoDB EC2 CloudFront
          </Keywords>
        </WorkBlock>


        <LineBreak width='75%' margin='20px auto' />

        <WorkBlock>
          <WorkBlockTitle>
            Software Developer - Rinel Lingo - Nov 2009 - Feb 2012
          </WorkBlockTitle>
          <WorkExperience>
            I was working on rebuilding a desktop app from Delphi to C++ using the Qt4 framework. My key
            responsibilities were in building up network interactions (transferring commands and files in the local
            network) and working with devices connected through COM ports.
          </WorkExperience>
          <Keywords>C++ Qt4</Keywords>
        </WorkBlock>

      </DetailedColumn>
    </Container>
  </div >
}

export default App;
