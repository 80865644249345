import styled from "styled-components";

export const SummaryColumn = styled.div`
  width: 20%;
  background: #ccc;
  padding: 20px 20px;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 30px);
    padding: 15px 15px;
  }
  @media print {
    background: #FFF;
  }
`